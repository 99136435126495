.mainContent{
    width: 100%;
    height: 100%;
    padding:2rem;
    overflow: auto;
    
    .bottom{
        margin-top: 2rem;
        gap:2rem;
        align-items: flex-start;
    }
}