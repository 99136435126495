:root{
    --PrimaryColor: hsl(0, 44%, 52%);
    --HoverColor: #f29f9f;
    --paleRed: hsl(0, 38%, 82%);
    --whiteColor: hsl(0,0%,100%);
    --blackColor: hsl(0,0%,18%);
    --textColor: hsl(240,1%,48%);
    --bgColor: hsl(220,10%,94%);
    --greyText: rgb(190, 190, 190);
    --imputColor: hsl(330, 12%, 97%);
  
    --biggestFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontSize: -.938rem;
    --smallFontSize: -.813rem;
    --normalFontSize: -.75rem;
  }
  .sideBar{
    background: var(--whiteColor) ;
    box-shadow: 1px 0px 4px var(--greyText);
    width: 14%;
    height:100% ;
    gap: 3rem;
    overflow: auto;

    .logoDiv{
        padding:1.5rem 1.5rem 0;
        cursor: pointer;

        img{
            max-width: 100px;
            margin-right: .5rem;
            background-color: transparent;
            border-radius: 2px;
            padding:.2rem;
        }
        h2{
            font-size: var(--h2FontSize);
            color: var(--PrimaryColor);
        }

    }
    .menuDiv{
        .divTitle{
            font-size: var(--h3FontSize);
            color: var(--blackColor);
            padding: 0 1.5rem 1.5rem;
        }

        .menuLists{
            .listItem{
                padding: .2rem 1.5rem;
                width: 100%;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    height: 0%;
                    left:0;
                    width: 5px;
                    bottom: 0;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background: var(--PrimaryColor);
                }
                .menuLink {
                    color: var(--greyText);
                    font-weight: 500;
                    transition: 0;
                    .icon{
                        margin-right: .5rem;
                    }  
                    
                }
                &:hover .menuLink{
                    color: var(--PrimaryColor);
                    transition: 0;
                }
                &:hover::before{
                    height: 100%;
                    transition: 0.0s ease;
                }
               
               
                
            }
            
            
        }
        
    }
   
    .sideBarCard{
        width: 100%;
        padding: 1rem;
        text-align: center;
        position: relative;

        .icon{
            position: absolute;
            background: var(--HoverColor);
            border: 10px solid var(--whiteColor);
            font-size: 3rem;
            border-radius: 50%;
            top: -8px;
            right: 50%;
            transform: translate(50%);
            z-index: 100;
        }
        .cardContent{
            position: relative;
            padding: 1rem;
            background: var(--HoverColor);
            border-radius: 10px;
            overflow: hidden;

            h3{
                font-size: var(--h3FontSize);
                margin-top: 1rem;
                padding: 1rem 0;
                font-weight: 800;
                color: var(--blackColor)
            }
            p{
                font-size: var(--normalFontSize);
                color: var(--textColor);
                padding-bottom: 1rem;
                font-weight: 500;
            }
            .btn{
                position: relative;
                color: var(--textColor);
                z-index: 1000;
            }
            .circle1, .circle2{
                position: absolute;
                background: var(--paleRed);
                border-radius: 50%;
                opacity: .7;
            }
            .circle1{
                height: 100px;
                width: 100px;
                top: -50px;
                left: -50px;
            }
            .circle2{
                height: 150px;
                width: 150px;
                top: -80px;
                left: -70px;
                z-index: 1;
            }
        }
    }
  }
  .listItem #active{
    color: var(--PrimaryColor);
}