.backdrop {
  background: #0505052b;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  width: 100vw;
  height: 100vh;
  z-index: 1000 !important;
}

.modal-container {
  background: white;
  width: 400px;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 0 3px #eeeeeede;
}

.modal-cover {
  margin-top: 30px;
}

.modal-entry {
  display: flex;
  justify-content: start;
  padding: 15px;
  border-bottom: 1px solid #dadada;
  cursor: pointer;
  margin-top: 8px;
  transition: all 100ms;
}

.modal-entry:hover {
  background: #efefef;
}

.modal-title {
  font-weight: bold;
  font-size: 17px;
}

.modal-icon,
.input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-text {
  padding-left: 10px;
}

.input-cover {
  display: flex;
  width: 100%;
  border: 1px solid #898989;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;
  height: 40px;
}

.input-form {
  border: none;
  width: 90%;
  padding: 5px;
}

.select-cover {
  width: 100%;
  height: 40px;
}

.input-icon {
  cursor: pointer;
}
.input-form:focus {
  border: none;
  outline: none;
}
