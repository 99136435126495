.mainContent {
  width: 86%;
  height: 100%;
  padding: 2rem;
  overflow: auto;
}
.mainContent .bottom {
  margin-top: 2rem;
  gap: 2rem;
  align-items: flex-start;
}
.mainContent .heading {
  width: 100%;
  justify-content: space-between;
}
.mainContent .heading h1 {
  flex-basis: 30%;
  width: 100%;
  font-size: var(--h1FontSize);
  font-weight: 700;
}
.mainContent .heading .tools {
  border: 1px solid transparent;
}
.mainContent .heading .tools .searchBar {
  padding: 1rem 2rem;
  background: var(--whiteColor);
  border-radius: 5px;
  gap: 1rem;
}
.mainContent .heading .tools .searchBar input {
  border: none;
  outline: none;
  background: none;
}
.mainContent .heading .tools .searchBar .icon {
  color: var(--textColor);
}
.mainContent .heading .tools .searchBar .icon:hover {
  color: var(--PrimaryColor);
} /*# sourceMappingURL=users.css.map */

/* Action btn */
.action-btn {
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  position: relative;
}

.modal-top {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.modal-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}
