:root {
  --PrimaryColor: hsl(0, 44%, 52%);
  --HoverColor: #f29f9f;
  --paleRed: hsl(0, 38%, 82%);
  --whiteColor: hsl(0,0%,100%);
  --blackColor: hsl(0,0%,18%);
  --textColor: hsl(240,1%,48%);
  --bgColor: hsl(220,10%,94%);
  --greyText: rgb(190, 190, 190);
  --imputColor: hsl(330, 12%, 97%);
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: -.938rem;
  --smallFontSize: -.813rem;
  --normalFontSize: -.75rem;
}

.main {
  overflow: hidden;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 95vw;
  height: 95vh;
  background: var(--bgColor);
  border-radius: 1rem;
  box-shadow: 2px 2px 8px var(--greyText);
}

.box-add-company {
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #fff;
  border: 3.3;
  box-shadow: 0 60px 40px -30px rba(0, 0, 0, 27);
  position: relative;
}

.inner-box {
  position: absolute;
  width: 100%;
  height: calc(100% - 4.1rem);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto; /* Added to make the content scrollable */
}

.forms-wrap-add_company {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.form {
  max-width: 75%;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.heading h2 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #151111;
}

.input-wrap {
  position: relative;
  height: 37px;
  margin-bottom: 2rem;
}

.input-field {
  position: absolute;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #bbb;
  padding: 0;
  font-size: var(--normalFontSize);
  color: #151111;
  transition: 0.4s;
}

label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--normalFontSize);
  -moz-columns: #bbb;
       columns: #bbb;
  pointer-events: none;
  transition: 0.4s;
}

.input-field.active {
  border-bottom-color: #151111;
}

.input-field.active + label {
  font-size: 0.75rem;
  top: -2px;
}

.sign-btn {
  display: inline-block;
  width: 100%;
  height: 43px;
  background-color: #151111;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 0.8rem;
  margin-bottom: 2rem;
  transition: 0.3s;
}

.sign-btn:hover {
  background-color: #f29f9f;
}

.text {
  font-size: var(--normalFontSize);
  color: #bbb;
}

.text a {
  color: #bbb;
  transition: 0.2s;
}

.text a:hover {
  color: #f29f9f;
}/*# sourceMappingURL=edit_opportunity.css.map */