.back-btn__cover {
  display: flex;
  align-items: center;
  justify-self: center;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.back-btn__icon-cover {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-right: 5px;
}
