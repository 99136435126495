@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  /* transition: 0.3s ease; */
}

:root {
  --PrimaryColor: hsl(0, 44%, 52%);
  --HoverColor: #f29f9f;
  --paleRed: hsl(0, 38%, 82%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(0, 0%, 0%);
  --bgColor: hsl(220, 10%, 94%);
  --greyText: rgb(190, 190, 190);
  --imputColor: hsl(330, 12%, 97%);

  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: -0.938rem;
  --smallFontSize: -0.813rem;
  --normalFontSize: -0.75rem;
}
html {
  font-size: 90%;
}

html,
body {
  height: 100%;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
.icon {
  font-size: var(--h2FontSize);
  cursor: pointer;
}
img {
  width: 100%;
  height: auto;
}
.flex {
  display: flex;
  align-items: center;
}
.grid {
  display: grid;
  align-items: center;
  gap: 1.5rem;
}
.btn {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--imputColor);
  padding: 0.8rem 1rem;
  color: black;
}
.btn .icon {
  margin-left: 0.5rem;
}
.btn:hover {
  background: var(--paleRed);
  color: var(--PrimaryColor);
}
body {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* margin: auto; */
  /* background-color: red !important; */
  padding: 0 !important;
}
.date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.searchBar {
  position: relative;
}

.searchHints {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  display: none;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
  z-index: 1000; /* Adjust the z-index value as needed */
}

.searchBar:hover .searchHints,
.searchBar input:focus + .searchHints {
  display: block;
}

#file-input {
  width: 105px;
  overflow: hidden;
}
.custom-date-picker {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.container {
  position: relative;
  /* width: 95vw;
  height: 95vh; */
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start !important;
  /* align-items: center; */
  justify-content: center;
  background: var(--bgColor);
  overflow: hidden;
  /* border-radius: 1rem; */
  border-radius: 0 !important;
  box-shadow: 2px 2px 8px var(--greyText);
}

::-webkit-scrollbar {
  display: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.error-popup {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
}

.css-1kwdphh-MuiDataGrid-virtualScrollerContent {
  background: #fff !important;
}

/* Additional styling for the close button */
.error-popup button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.mainContent {
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow: auto;

  .bottom {
    margin-top: 2rem;
    gap: 2rem;
    align-items: flex-start;
  }
}
.error-popup button:hover {
  background-color: #d32f2f;
}
.comTitle {
  color: rgb(4, 4, 27); /* Change color to blue */
  font-size: 24px; /* Add your desired font size */
  /* Add any additional styling you need */
}

.custom-address_title {
  font-size: 14px !important;
}

/* react select  */
.css-1jqq78o-placeholder {
  font-size: 13px !important;
}
.rule-title {
  margin-top: 12px !important;
  padding: 5px 0 6px 0;
  font-style: italic;
  color: #494949;
}
.horizontal-rule {
  margin-top: 0 !important;
  border: 0.5px solid #e2e2e2;
  color: #b54b4b;
}

.filter-labels {
  font-weight: bold !important;
  font-size: 13px !important;
  padding: 0 0 5px 0 !important;
}

.mainContent2 {
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow: auto;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  .bottom {
    margin-top: 2rem;
    gap: 2rem;
    align-items: flex-start;
  }
  .heading {
    width: 100%;
    justify-content: space-between;
    h1 {
      flex-basis: 30%;
      width: 100%;
      font-size: var(--h1FontSize);
      font-weight: 700;
    }
    .tools {
      border: 1px solid transparent;

      .searchBar {
        padding: 1rem 2rem;
        background: var(--whiteColor);
        border-radius: 5px;
        gap: 1rem;
        input {
          border: none;
          outline: none;
          background: none;
        }
        .icon {
          color: var(--textColor);
          &:hover {
            color: var(--PrimaryColor);
          }
        }
      }
    }
  }
}

/* MEDIA QUERIES */
@media only screen and (max-width: 600px) {
  .header-left_icon {
    display: block !important;
  }
  .mobile-backdrop {
    display: block !important;
  }
  .mobile-nav {
    /* display: flex !important; */
  }
  .mainContent {
    padding: 1rem !important;
  }
  .sideBar {
    display: none;
  }

  .filterCard.grid {
    display: none;
  }

  .header-title {
    display: none !important;
  }
  .header-caption {
    font-size: 16px !important;
    color: #222222 !important;
  }
  /* menu icon */
  .header-left_cover {
    display: flex;
    align-items: center;
  }

  .listingSection .heading .flex .list-box {
    width: 100% !important;
    background: red;
  }

  .tools .flex {
    width: 100% !important;
  }

  .listingSection .heading {
    flex-direction: column !important;
    width: fit-content !important;
  }

  .listingSection .heading .tools .searchBar {
    width: 100% !important;
  }

  .listingSection .heading .tools .searchBar input {
    width: 100% !important;
  }

  .listingSection .heading .tools {
    width: 100% !important;
  }

  .listingSection .heading .tools {
    margin-top: 10px !important;
  }
}
