.mainContent{
    width: 86%;
    height: 100%;
    padding:2rem;
    overflow: auto;
    
    .bottom{
        margin-top: 2rem;
        gap:2rem;
        align-items: flex-start;
    }
    .heading{
        width: 100%;
        justify-content: space-between;
        h1{
            flex-basis: 30%;
            width: 100%;
            font-size: var(--h1FontSize);
            font-weight: 700;
        }
        .tools{
            border: 1px solid transparent;
            
            .searchBar{
                padding: 1rem 2rem;
                background: var(--whiteColor);
                border-radius: 5px;
                gap: 1rem;
                input{
                    border: none;
                    outline: none;
                    background: none;
                }
                .icon{
                    color: var(--textColor);
                    &:hover{
                        color: var(--PrimaryColor);
                    }
                }
               }
        }
    }
}