:root{
    --PrimaryColor: hsl(0, 44%, 52%);
    --HoverColor: #f29f9f;
    --paleRed: hsl(0, 38%, 82%);
    --whiteColor: hsl(0,0%,100%);
    --blackColor: hsl(0,0%,18%);
    --textColor: hsl(240,1%,48%);
    --bgColor: hsl(220,10%,94%);
    --greyText: rgb(190, 190, 190);
    --imputColor: hsl(330, 12%, 97%);
    --itemCardColor: hsl(0, 0%, 100%);
    --itemCardHover: hsl(120, 27%, 87%);
  
    --biggestFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontSize: -.938rem;
    --smallFontSize: -.813rem;
    --normalFontSize: -.75rem;
  }
  .listingSection{
    flex-basis: 100%;
    .heading{
        width: 100%;
        justify-content: space-between;
        h1{
            flex-basis: 30%;
            width: 100%;
            font-size: var(--h1FontSize);
            font-weight: 700;
        }
        .tools{
            border: 1px solid transparent;
          
            .searchBar{
                padding: 1rem 2rem;
                background: var(--whiteColor);
                border-radius: 5px;
                gap: 1rem;
                input{
                    border: none;
                    outline: none;
                    background: none;
                }
                .icon{
                    color: var(--textColor);
                    &:hover{
                        color: var(--PrimaryColor);
                    }
                }
               }
        }
    }
    .secContainer{
        width: 100%;
        margin: 1rem 0;
        gap: 1rem;
        flex-wrap:wrap ;
        justify-content: space-between;

        .singleItem{
            position: relative;
            width: 100%;
            display: inline-block;
            justify-content: space-between;
            flex-direction: row;
            padding: 1rem;
            border-radius: 10px;
            background-color: var(--itemCardColor);
            .fav-btutton{
                border: none;
                outline: none;
                cursor: pointer;
                background: none;
                box-shadow: none;
            }
            
            }
            .title{
                position: relative;
                display: flex;
                width: 100%;
                font-size: var(--h1FontSize);
                justify-content: space-between;
                .tools{
                    display: grid;
                    padding: 0.5rem;
                    .btn{
                        position: relative;
                        color: var(--textColor);
                    }
            }

        }
        .properties{
            p{
                font-size: var(--h2FontSize);
                padding: 0.0rem 0.5rem 1rem;
            }
        }
        .allTags{
            div{
                font-size: var(--h3FontSize);
                padding: 0.0rem 0.5rem 1rem;
            }
        }
        .breifDescription{
            font-size: var(--h2FontSize);
        }

        
    }
    
    
  }
  .filterCard{
    flex-basis: 20%;
    position: relative;   
    height: 100%;
    align-items:center ;
    flex-direction: row;
    padding: 0.5rem;
    border-radius: 10px;
    background-color: var(--itemCardColor);
    .heading{
        justify-content: space-between;
        h2{
            flex-basis: 30%;
            width: 100%;
            font-size: var(--h2FontSize);
            font-weight: 700;
        }
    }
    .option{
        .card {
            padding: 1rem;
            border-radius: 10px;
            margin-bottom: 1rem;
        }
    }
  }