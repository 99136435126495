:root{
    --PrimaryColor: hsl(0, 44%, 52%);
    --HoverColor: #f29f9f;
    --paleRed: hsl(0, 38%, 82%);
    --whiteColor: hsl(0,0%,100%);
    --blackColor: hsl(0,0%,18%);
    --textColor: hsl(240,1%,48%);
    --bgColor: hsl(220,10%,94%);
    --greyText: rgb(190, 190, 190);
    --imputColor: hsl(330, 12%, 97%);
  
    --biggestFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontSize: -.938rem;
    --smallFontSize: -.813rem;
    --normalFontSize: -.75rem;
  }
  .topSection{
    .headerSection{
        justify-content: space-between;
       .title{
        h1{
            font-size: var(--h1FontSize);
            font-weight: 700;
            color: var(--blackColor);
        }
        p{
            font-size: var(--normalFontSize);
            color: var(--textColor);
            font-weight: 500;
        }
       }
       .searchBar{
        padding: 1rem 2rem;
        background: var(--whiteColor);
        border-radius: 5px;
        gap: 1rem;
        input{
            border: none;
            outline: none;
            background: none;
        }
        .icon{
            color: var(--textColor);
            &:hover{
                color: var(--PrimaryColor);
            }
        }
       }
       .adminDiv{
        gap: 1rem;
        .icon{
            font-size: var(--biggestFontSize);
            background: var(--whiteColor);
            border-radius: 5px;
            padding: 5px;
            box-shadow: 0 2px 4pxx var(--imputColor);
            color: var(--textColor);
        }
        .adminImage{
            border: 3px solid var(--whiteColor);
            border-radius: 10px;
            width: 2.5rem;
          
            box-shadow: 0 2px 4pxx var(--imputColor);

            img{
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }
       }
    }
  }

  
  
  ul{
    list-style: none;
  }
  
  a {
    text-decoration: none;
  }
  
  .menu-trigger img{
    position: absolute;
    top: 20px;
    right: 20px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;
  }
  
  .dropdown-menu {
    position: fixed;
    top: 90px; /* Adjust this value as needed to set the distance from the top */
    right: 20px; /* Adjust this value as needed to set the horizontal position */
    background-color: #fff;
    border-radius: var(--border-radius);
    padding: 10px 20px;
    width: 200px;
    z-index: 999; /* Add this line to ensure the dropdown stays on top */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  
  .dropdown-menu::before{
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;
    height: 20px;
    width: 20px;
    background: var(--secondary-bg);
    transform: rotate(45deg);
  }
  
  .dropdown-menu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }
  
  .dropdown-menu.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: var(--speed) ease;
  }
  
  
 
  
 
  
  .dropdown-menu ul li{
    padding: 10px  0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .dropdown-menu ul li:hover a{
    color: rgb(212, 33, 9);
    cursor: pointer;
  }
  
  .dropdown-menu ul li:hover img{
    opacity: 1;
    cursor: pointer;
  }
  
  .dropdownItem{
    display: flex;
    margin: 10px auto;
  }
  
  .dropdownItem img{
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: var(--speed);
  }
  
  .dropdownItem a{
    max-width: 100px;
    margin-left: 10px;
    transition: var(--speed);
  }