:root {
  --PrimaryColor: hsl(0, 44%, 52%);
  --HoverColor: #f29f9f;
  --paleRed: hsl(0, 38%, 82%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --bgColor: hsl(220, 10%, 94%);
  --greyText: rgb(190, 190, 190);
  --imputColor: hsl(330, 12%, 97%);
  --itemCardColor: hsl(0, 0%, 100%);
  --itemCardHover: hsl(120, 27%, 87%);
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 0.7rem;
  --h3FontSize: 1rem;
  --normalFontSize: -0.938rem;
  --smallFontSize: -0.813rem;
  --normalFontSize: -0.75rem;
}

.listingSection {
  flex-basis: 100%;
}
.listingSection .heading {
  width: 100%;
  justify-content: space-between;
}
.listingSection .heading h1 {
  flex-basis: 30%;
  width: 100%;
  font-size: var(--h1FontSize);
  font-weight: 700;
}
.listingSection .heading .tools {
  border: 1px solid transparent;
}
.listingSection .heading .tools .searchBar {
  padding: 1rem 2rem;
  background: var(--whiteColor);
  border-radius: 5px;
  gap: 1rem;
}
.listingSection .heading .tools .searchBar input {
  border: none;
  outline: none;
  background: none;
}
.listingSection .heading .tools .searchBar .icon {
  color: var(--textColor);
}
.listingSection .heading .tools .searchBar .icon:hover {
  color: var(--PrimaryColor);
}
.listingSection .secContainer {
  width: 100%;
  margin: 1rem 0;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.listingSection .secContainer .singleItem {
  position: relative;
  width: 100%;
  display: inline-block;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--itemCardColor);
}
.listingSection .secContainer .singleItem .fav-btutton {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  box-shadow: none;
}
.listingSection .secContainer .title {
  position: relative;
  display: flex;
  width: 100%;
  font-size: var(--h1FontSize);
  justify-content: space-between;
}
.listingSection .secContainer .title .tools {
  display: grid;
  padding: 0.5rem;
}
.listingSection .secContainer .title .tools .btn {
  position: relative;
  color: var(--textColor);
}
.listingSection .secContainer .properties p {
  font-size: var(--h2FontSize);
  padding: 0rem 0.5rem 1rem;
}
.listingSection .secContainer .allTags div {
  font-size: var(--h3FontSize);
  padding: 0rem 0.5rem 1rem;
}
.listingSection .secContainer .breifDescription {
  font-size: var(--h2FontSize);
  padding: 10px 0 !important;
}

.breifDescription.flex > p,
.custom-address_title {
  font-size: 15px !important;
}

.filterCard {
  flex-basis: 20%;
  position: relative;
  height: 100%;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: var(--itemCardColor);
}
.filterCard .heading {
  justify-content: space-between;
}
.filterCard .heading h2 {
  flex-basis: 30%;
  width: 100%;
  font-size: var(--h2FontSize);
  font-weight: 700;
}
.filterCard .option .card {
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
} /*# sourceMappingURL=listing.css.map */
