.mainContent {
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow: auto;
}
.mainContent .bottom {
  margin-top: 2rem;
  gap: 2rem;
  align-items: flex-start;
} /*# sourceMappingURL=analytics.css.map */
.analytic_cover {
  /* border: 1px solid grey; */
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.analytic_card {
  width: 24%;
  padding: 13px;
  height: 140px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 3px 5px #eeeeeede;
}

.analytic_title {
  font-size: 18px;
  font-weight: 500;
  color: #131313;
}

.analytic_value {
  font-size: 35px;
  font-weight: 600;
  padding: 20px;
}

.chart-container {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.chart-cover {
  width: 100%;
  padding: 10px;
  height: 500px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 3px 5px #eeeeeede;
}

.chart-title {
  font-weight: 500;
}
