.mobile-backdrop {
  width: 100vw !important;
  height: 100vh !important;
  background: #06060630;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 1000 !important;
}

.mobile-nav {
  position: relative;
  transition: all 350ms ease;
  animation: mobileslide 350ms ease;
  background: #fff;
  box-shadow: 0 0 3px 5px #16161626;
  padding: 10px;
  flex-direction: column;
}

.mobile-items-link {
  padding: 12px 10px;
  width: fit-content;
  font-size: 16px;
  font-weight: 400;
  /* display: block; */
  color: rgb(22, 22, 22);
}

.mobile-items_cover {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.mobile-top__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-nav_title {
  font-weight: 600;
  font-size: 16px;
}

@keyframes mobileslide {
  from {
    top: -200px;
  }
  to {
    top: 0;
  }
}
